<div *ngIf="isAuthenticated; else generalInfo" class="grid-container d-flex align-items-lg-center">
  <div class="container-fluid">
    <div class="row">
      <div *ngFor="let item of aiPortalItems" class="grid-item col-sm-12 col-md-6 col-lg-4">
        <app-landing-page-tile [item]="item"></app-landing-page-tile>
      </div>
    </div>
  </div>
</div>



<ng-template #generalInfo>
  <div class="page-container mt-3">
    <div class="text-container">
      <p>To use the Digiteq AI Portal applications you need to sign in first.</p>
      <p>If you don't have your sign in credentials yet, please contact us using the button in the bottom right corner of the screen.</p>
    </div>
  </div>
</ng-template>

