import { Injectable } from '@angular/core';
import {
  ChatConstants,
  ChatSettings,
  ChatSettingsRanges,
  SettingsService as ApiSettingsService,
  GPTVersion
} from 'digiteq-ai-portal-client-lib';
import { Observable, of, Subject } from 'rxjs';

const LOCAL_STORAGE_SETTINGS = 'dq-chat-settings'

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  $settingsChange = new Subject<ChatSettings>();

  get gptVersion(): GPTVersion {
    return this._gptVersion;
  }

  set gptVersion(value: GPTVersion) {
    this._gptVersion = value;
  }

  get savedSettings(): ChatSettings {
    const storageSettings = localStorage.getItem(LOCAL_STORAGE_SETTINGS);
    return JSON.parse(storageSettings)
  }

  set savedSettings(value: ChatSettings) {
    localStorage.setItem(LOCAL_STORAGE_SETTINGS, JSON.stringify(value));
    this.$settingsChange.next(value)
  }

  private _gptVersion: GPTVersion;

  constructor(
    private apiSettingsService: ApiSettingsService,
  ) {
    this._gptVersion = this.savedSettings?.version || GPTVersion.Gpt35;
  }

  getInitialSettings(): Observable<ChatSettings> {
    const localSettings = localStorage.getItem(LOCAL_STORAGE_SETTINGS)
    if (localSettings) {
      return of(JSON.parse(localSettings) as ChatSettings);
    }
    return this.getDefaultSettings();
  }

  getDefaultSettings(): Observable<ChatSettings> {
    return this.apiSettingsService.getDefaultSettings();
  }

  getSettingsRanges(): Observable<ChatSettingsRanges> {
    return this.apiSettingsService.getSettingsRanges();
  }

  getConstants(): Observable<ChatConstants> {
    return this.apiSettingsService.getConstants();
  }

  getGptVersionDisplayName(): string {
    switch(this.savedSettings.version) {
      case GPTVersion.Gpt35:
        return "GPT-3.5 Turbo";
      case GPTVersion.Gpt4:
        return "GPT-4o";
      case GPTVersion.Gpt4oMini:
        return "GPT-4o-mini"; 
      default:
        return undefined;
    }
  }
}
