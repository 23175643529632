import { Injectable, Optional } from '@angular/core';
import {
  ChatResponseModel,
  ChatService,
  CompanyChatRequestModel,
  CompanyChatResponseModel,
  Configuration,
  GenericChatRequestModel,
} from "digiteq-ai-portal-client-lib";
import { HttpClient } from "@angular/common/http";
import { OidcSecurityService } from "@digiteq/angular-auth-oidc-client";
import { mergeMap, Observable, Observer } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class StreamChatService extends ChatService {
  constructor(
    httpClient: HttpClient,
    @Optional() configuration: Configuration,
    private securityService: OidcSecurityService) {
    super(httpClient, null, configuration);
  }

  private fetchChat<T>(model: CompanyChatRequestModel | GenericChatRequestModel, url: string, token: string): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      fetch(environment.apiUrl + url, {
        method: "post", headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(model),
      }).then(async e => {
        if (!e.ok) {
          observer.error("Server error");
          return {
            unsubscribe() {
            },
          };
        }
        const reader = e.body.getReader();
        const decoder = new TextDecoder('utf-8');

        while (true) {
          const {done, value} = await reader.read();

          if (done) {
            observer.complete();
            break;
          }
          const text = decoder.decode(value);
          if (text == "ERROR") {
            observer.error("Stream error")
            return {
              unsubscribe() {
              },
            };
          }
          const texts = text.split('}{');
          texts.forEach((t, index) => {
            if (index !== 0) {
              t = '{' + t;
            }
            if (index !== texts.length - 1) {
              t += '}';
            }
            observer.next(JSON.parse(t));
          });
        }
        return {
          unsubscribe() {
          },
        };
      }).catch(e => {
        console.error(e)
        observer.error("Response error")
      });
    });
  }

  public realGenericChatStream(model: GenericChatRequestModel): Observable<ChatResponseModel> {
    return this.securityService.getAccessToken()
      .pipe(mergeMap(token => {
        return this.fetchChat<ChatResponseModel>(model, "/chat/generic/stream", token);
      }));
  }

  public realCompanyChatStream(model: CompanyChatRequestModel): Observable<CompanyChatResponseModel> {
    return this.securityService.getAccessToken()
      .pipe(mergeMap(token => {
        return this.fetchChat<CompanyChatResponseModel>(model, "/chat/company/stream", token);
      }));
  }
}
