import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SettingsService } from '../../services/settings.service';
import { AuthService, UserData } from '../../services/auth.service';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { HelpComponent } from '../../pages/help/help.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userMenuItems: MenuItem[] | undefined;
  helpMenuItems: MenuItem[] | undefined;

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  };

  get currentUser(): UserData {
    return this.authService.currentUser;
  }

  get gptVersion(): string {
    return this.settingsService.getGptVersionDisplayName();
  }

  constructor(
    private settingsService: SettingsService,
    private authService: AuthService,
    private router: Router,
    private dialog: DialogService,
  ) {}

  ngOnInit() {
    this.userMenuItems = [
      {
        label: 'My issues',
        icon: 'pi pi-check-square',
        routerLink: 'work-items'
      },
      {
        separator:true
      },
      {
        label: 'Log out',
        icon: 'pi pi-sign-out',
        command: () => { this.logout()}
      },
    ];

    this.helpMenuItems = [
      {
        label: 'Help',
        icon: 'pi pi-question',
      },
      {
        label: 'Licence info',
        icon: 'pi pi-book'
      }
    ];
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  toLandingPage() {
     this.router.navigate(['']);
  }

  showHelp() {
    this.dialog.open(HelpComponent, {
      modal: true,
      width: '75%',
    })
  }

  isChat() {
    return this.router.url === '/chat';
  }
}
