import { Injectable } from '@angular/core';
import { ApplicationInsights, eSeverityLevel, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { OidcSecurityService, UserDataResult } from '@digiteq/angular-auth-oidc-client';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AzureLoggerService {

  private appInsights: ApplicationInsights;
  private user: any;

  constructor(
    private oidcSecurityService: OidcSecurityService,
  ) {
    this.subscribeToUser();
  }

  public logError(err: Error) {
    this.appInsights.trackException({ exception: err, severityLevel: eSeverityLevel.Error });
  }

  private initAppInsights() {

    this.appInsights  = new ApplicationInsights({
      config: {
        endpointUrl: environment.appInsights.endpointUrl,
        instrumentationKey: environment.appInsights.dummyKey,
        enableAutoRouteTracking: true, // option to log all route changes
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableCorsCorrelation: true,
        excludeRequestFromAutoTrackingPatterns: [environment.authentication.authority],
        maxAjaxCallsPerView: -1,
        extensionConfig: {
          ['AppInsightsCfgSyncPlugin']: {
            cfgUrl: ''
          }
        }
      }
    });

    const telemetryInitializer = (item: ITelemetryItem) => {
      if (item.data) {
        item.data.ApplicationName = environment.application;
        item.data.LoggedUsername = this.user.name;
        item.data.LoggedUserDzc = this.user.preferred_username;
      }
    };

    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  private subscribeToUser() {
    this.oidcSecurityService.userData$.subscribe(
      (user: UserDataResult) => {
        this.user = user.userData;
        this.initAppInsights();
      });
  }
}
