import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { AzureLoggerService } from './azure-logger.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private messageService: MessageService,
    private azureService: AzureLoggerService,
  ) { }

  handleError(error: HttpErrorResponse, detail: string) {
    console.error(error);
    this.messageService.add({
      severity: 'error',
      detail,
      summary: 'Error',
      life: 3000,
    });
    this.azureService.logError(error);
  }
}
