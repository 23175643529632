import { Component } from '@angular/core';
import { ChatType } from '../../models/chat-type';

@Component({
  selector: 'app-company-chat',
  templateUrl: './company-chat.component.html',
  styleUrls: ['./company-chat.component.scss']
})
export class CompanyChatComponent {
  chatType = ChatType;
}
