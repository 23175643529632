<div class="history-item"
     [ngStyle]="{'padding': editingChatName ? '0 0.75rem' : '0.5rem 0 0.5rem 0.5rem'}"
     (mouseenter)="isHovered = true"
     (mouseleave)="onChatHistoryMenuMouseLeave()"
     (click)="loadHistory()">
    <div *ngIf="!editingChatName"
         class="my-auto ms-2 text-truncate"
         [ngClass]="{'current-chat': isCurrentChat}"
         [ngStyle]="{'max-width': isHovered ? '80%' : '100%'}"
         [pTooltip]="chat.name">
      {{chat.name}}
    </div>
    <div *ngIf="!editingChatName" style="position: relative; height: 0;">
      <div style="position: absolute; left: -38px;">
        <p-menu #historyMenu [model]="historyMenuItems" [popup]="true" appendTo="body" (onShow)="historyMenuShown = true"
                (onHide)="onChatHistoryMenuHide()"/>
        <p-button *ngIf="isHovered" icon="pi pi-ellipsis-v" [text]="true" severity="secondary" (click)="historyMenu.toggle($event)"/>
      </div>
    </div>
    <div *ngIf="editingChatName" class="my-auto ms-3 d-flex">
      <input id="edit-chat-name" type="text" pInputText [(ngModel)]="chatName"/>
    </div>
</div>
