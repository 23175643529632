export enum SettingsItems {
  gptVersion = 'gptVersion',
  prompt = 'prompt',
  maxTokens = 'maxTokens',
  temperature = 'temperature',
  nucleusSamplingFactor = 'nucleusSamplingFactor',
  stopSequence = 'stopSequence',
  frequencyPenalty = 'frequencyPenalty',
  presencePenalty = 'presencePenalty',
  lastMessages = 'lastMessages',
}
