import { Component, OnInit } from '@angular/core';
import { WorkItemModel, WorkItemService, WorkItemType } from 'digiteq-ai-portal-client-lib';
import { HttpErrorResponse } from '@angular/common/http';
import { faBug, faComment, faLightbulb} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-work-items',
  templateUrl: './work-items.component.html',
  styleUrls: ['./work-items.component.scss']
})
export class WorkItemsComponent implements OnInit {


  faBug = faBug;
  faComment = faComment;
  faLightBulb = faLightbulb;

  workItemTypes = WorkItemType;

  workItems: WorkItemModel[];
  loading: boolean;

  selectedWorkItem: WorkItemModel;
  constructor(private workItemService: WorkItemService) {
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.loading = true;
    this.workItemService.myWorkItems().subscribe({
      next: (workItems: WorkItemModel[]) => {
        this.workItems = workItems;
        this.selectedWorkItem = this.workItems[0];
      },
      error: (err: HttpErrorResponse) => {

      },
      complete: () => {
        this.loading = false;
      }
    })
  }

}
