<div class="m-5"
     (click)="onItemClick(item.routerLink)"
     (mouseover)="showDescription = true"
     (mouseout)="showDescription = false">
  <div class="ai-portal-item text-container" [ngClass]="{'enabled': item.enabled}">
    <i class="ai-home-icon" [class]="item.icon" [ngClass]="{'grey': !item.enabled}"></i>
    <div [ngClass]="{'grey': !item.enabled}">{{item.title}}</div>
  </div>
  <div class="mt-2" [style.visibility]="showDescription ? 'visible' : 'hidden'">{{item.description}}</div>
</div>

