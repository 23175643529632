import { Component, Input } from '@angular/core';
import {
  WorkItemCommentModel,
  WorkItemDetailModel,
  WorkItemModel,
  WorkItemService
} from 'digiteq-ai-portal-client-lib';

@Component({
  selector: 'app-work-item-detail',
  templateUrl: './work-item-detail.component.html',
  styleUrls: ['./work-item-detail.component.scss']
})
export class WorkItemDetailComponent {

  private _workItem: WorkItemModel;

  @Input()
  get workItem(): WorkItemModel {
    return this._workItem;
  }

  set workItem(value: WorkItemModel) {
    this._workItem = value;
    this.load();
  }

  workItemDetail?: WorkItemDetailModel;
  constructor(private workItemService: WorkItemService) {
  }

  load(): void {
    // this.workItemDetail = this.workItemDetails.find(wid => wid.id === this.workItem?.id);
    if (this.workItem?.id) {
      this.workItemService.getWorkItem(this.workItem.id).subscribe((workItemDetail: WorkItemDetailModel) => {
        this.workItemDetail = workItemDetail;
      })
    }
  }
}
