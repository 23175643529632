import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { WorkItemModel, WorkItemService, WorkItemType } from 'digiteq-ai-portal-client-lib';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  display = false;
  contactForm: FormGroup;
  processingRequest = false;
  routerSub: Subscription;
  types = Object.values(WorkItemType);

  constructor(
    private formBuilder: FormBuilder,
    private workItemService: WorkItemService,
    private router: Router,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      type: [WorkItemType.Bug]
    })

    this.routerSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.display = false;
      });
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

  submitForm(): void {
    this.processingRequest = true;
    const contactForm: WorkItemModel = this.contactForm.value;
    this.workItemService.createWorkItem(contactForm).subscribe({
      next: () => {
        this.display = !this.display;
        this.processingRequest = false;
        this.contactForm.reset();
        this.messageService.add({ severity: 'success', detail: 'Your message was sent and registered.', summary: 'Success', life: 3000 })
      },
      error: err => {
        this.processingRequest = false;
        this.messageService.add({ severity: 'error', detail: 'Could not send your message.', summary: 'Error', life: 3000 })
      }
    });
  }

  showDialog() {
    this.display = true;
  }

  protected readonly WorkItemType = WorkItemType;
}
