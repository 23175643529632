import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkItemsComponent } from './work-items.component';
import { WorkItemDetailComponent } from './work-item-detail/work-item-detail.component';

const routes: Routes = [
  {
    path: '',
    component: WorkItemsComponent
  },
  {
    path: ':id',
    component: WorkItemDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkItemsRoutingModule { }
