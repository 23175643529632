<div *ngIf="workItemDetail">
  <h2>{{ workItemDetail.title }}</h2>
  <div class="d-flex">
    <div class="d-flex flex-row mb-3 align-items-baseline me-5">
      <label class="header-label me-2">State</label>
      <span>{{ workItemDetail.state }}</span>
    </div>

    <div class="d-flex flex-row mb-3 align-items-baseline">
      <label class="header-label me-2">Type</label>
      <span>{{ workItemDetail.type }}</span>
    </div>
  </div>

  <div class="d-flex flex-column mb-3">
    <label class="fw-bold mb-2">Description</label>
    <div [innerHTML]="workItemDetail.description"></div>
  </div>

  <div class="d-flex flex-column mb-3">
    <label class="fw-bold mb-2">Result</label>
    <div [innerHTML]="workItemDetail.result"></div>
  </div>

  <div class="d-flex flex-column mb-3">
    <label class="fw-bold mb-2">Comments</label>
    <div>
      <div *ngFor="let comment of workItemDetail.comments" class="mb-3">
        <div>{{ comment.created | date: 'dd.MM.yyyy HH:mm' }}</div>
        <div>{{ comment.author }}</div>
        <div [innerHTML]="comment?.comment"></div>
      </div>
    </div>
  </div>
</div>
