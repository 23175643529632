<!--<p-button icon="pi pi-check" styleClass="p-button-rounded" (click)="showDialog()"></p-button>-->
<p-dialog header="Contact us or report issue" [(visible)]="display" [draggable]="false" styleClass="w-50"
          [modal]="true">
  <form [formGroup]="contactForm" (ngSubmit)="submitForm()">
    <div class="mb-2">
        <span class="p-float-label">
            <input pInputText id="form-title" type="text" formControlName="title" class="w-100">
            <label for="form-title">Subject of message</label>
        </span>
    </div>

    <div class="mt-3">
      <div class="d-flex flex-row align-items-center">
        <div *ngFor="let type of types" class="field-checkbox me-2">
          <p-radioButton [inputId]="type" [value]="type" formControlName="type" class="me-1"></p-radioButton>
          <label [for]="type" class="ml-2">{{ type }}</label>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <p-editor id="form-body" formControlName="description" [style]="{ height: '200px' }"></p-editor>
    </div>

    <div class="col-12 mt-1">
      <button class="float-end" pButton pRipple type="submit" label="Send" [loading]="processingRequest"
              [disabled]="contactForm.invalid"></button>
    </div>
  </form>
</p-dialog>

<div id="contact-us">
  <p-button icon="pi pi-envelope" [rounded]="true" (click)="showDialog()"
            pTooltip="Contact us or report issue"></p-button>
</div>


