<div class="container">

  <h3 id="cz">CZ</h3>
  <h2 id="n-pov-da-k-aplikaci">Nápověda k aplikaci</h2>
  <p>Tato aplikace využívá model GPT 3.5 Turbo a funguje tedy velice podobně jako ChatGPT. Stačí zadat dotaz a model
    vygeneruje odpověď.</p>
  <h2 id="s-m-m-e-aplikace-pomoci">S čím může aplikace pomoci</h2>
  <ul>
    <li>obecné rady</li>
    <li>překlady a korekce</li>
    <li>generování textů a sumarizací</li>
    <li>generování kódu a SQL dotazů</li>
  </ul>
  <h2 id="k-emu-by-se-aplikace-nem-la-pou-vat">K čemu by se aplikace neměla používat</h2>
  <ul>
    <li>vyhledávání interních informací * aplikace nemá přístup k interním datům</li>
    <li>dotazování na aktuální informace</li>
    <li>vyhledávání na internetu</li>
  </ul>
  <h3 id="d-le-it-pozn-mka-">Důležitá poznámka:</h3>
  <p>Jedná se pouze o podpůrný nástroj a u odpovědí vygenerovaných umělou inteligencí nelze zaručit jejich správnost.
    Před použitím vygenerovaného obsahu si prosím vždy překontrolujte jeho správnost.</p>
  <h2 id="hl-en-chyb-a-zp-tn-vazba">Hlášení chyb a zpětná vazba</h2>
  <p>Pomocí ikonky obálky (vpravo dole) lze otevřít formulář, přes který je možné hlásit chyby a posílat zpětnou vazbu
    či nápady na funkce aplikace.</p>
  <h2 id="pl-novan-funkce">Plánované funkce</h2>
  <ul>
    <li>chat s vlastními daty</li>
    <li>generování obrázků pomocí DALL-E</li>
    <li>completions funkcionalita</li>
    <li>přepis řeči na text pomocí Whisper modelu</li>
  </ul>
  <br>
  <br>
  <h3 id="en">EN</h3>
  <h2 id="help">Help</h2>
  <p>This application uses the GPT 3.5 Turbo model and works very similarly to ChatGPT. Simply enter a prompt and the
    model will generate a response.</p>
  <h2 id="what-the-app-can-help-with-">What the app can help with:</h2>
  <ul>
    <li>general knowledge and advice</li>
    <li>translation and correction of texts</li>
    <li>text generation and summary</li>
    <li>code and SQL query generation</li>
  </ul>
  <h2 id="what-the-app-can-not-help-with-">What the app can NOT help with:</h2>
  <ul>
    <li>searching for internal company information - the app does not have access to internal data</li>
    <li>retrieving current or recent information</li>
    <li>searching the internet</li>
  </ul>
  <h3 id="disclaimer-">Disclaimer:</h3>
  <p>This app should serve only as a helpful tool, the accuracy of AI-generated content cannot be guaranteed. Please
    review the correctness of the generated content before using it.</p>
  <h2 id="feedback-and-bug-reporting">Feedback and bug reporting</h2>
  <p>You can open a form by clicking on the envelope icon (bottom right), through which bug reports, feedback of feature
    ideas can be sent.</p>
  <h2 id="planned-functions">Planned functions</h2>
  <ul>
    <li>chat with your own data</li>
    <li>image generation with DALL-E</li>
    <li>completions functionality</li>
    <li>speech to text with Whisper</li>
  </ul>
</div>
