import { Component, OnInit } from '@angular/core';
import { AiPortalItem } from '../../models/ai-portal-item';
import { AuthService } from '../../services/auth.service';
import { UserRole } from 'digiteq-ai-portal-client-lib';

const AI_PORTAL_ITEMS: AiPortalItem[] = [
  {id: 0, title: 'Chat', description: 'GPT chat with public data', icon: 'fa-regular fa-comments', routerLink: 'chat', enabled: true},
  {id: 1, title: 'DAISE', description: 'Digiteq AI Search', icon: 'fa-solid fa-building', routerLink: 'company-chat', enabled: true},
  {id: 2, title: 'Available soon', description: 'Available soon', icon: 'fa-solid fa-lock', routerLink: '', enabled: false}
];

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit{
  aiPortalItems: AiPortalItem[] = AI_PORTAL_ITEMS;
  isAuthenticated = false;

   constructor(
    private authService: AuthService,
   ) {
  }

  ngOnInit() {
     this.authService.isAuthenticated$.subscribe(isAuth => {
       this.isAuthenticated = isAuth;
     })

    this.authService.currentUser$.subscribe(user => {
      if (user) {
        this.setItems();
      }
    })
   }

  setItems(): void {
    this.checkAccessRights('Chat', [UserRole.Chat]);
    this.checkAccessRights('DAISE', [UserRole.CompanyChat]);
  }

  checkAccessRights(app: string, roles: UserRole[]) {
    const index = this.aiPortalItems.findIndex(api => api.title === app);
    const chat = this.aiPortalItems[index];
    if (!this.authService.isInRoles(roles)) {
      chat.enabled = false;
      chat.routerLink = '';
      chat.description = 'You don\'t have right for this app...';
    } else {
      chat.enabled = true;
      chat.routerLink = AI_PORTAL_ITEMS[index].routerLink;
      chat.description = AI_PORTAL_ITEMS[index].description;
    }
  }

}
