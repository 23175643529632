import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkItemsRoutingModule } from './work-items-routing.module';
import { WorkItemsComponent } from './work-items.component';
import { WorkItemDetailComponent } from './work-item-detail/work-item-detail.component';
import { TableModule } from 'primeng/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardModule } from 'primeng/card';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { DividerModule } from 'primeng/divider';


@NgModule({
  declarations: [
    WorkItemsComponent,
    WorkItemDetailComponent
  ],
	imports: [
		CommonModule,
		WorkItemsRoutingModule,
		TableModule,
		FontAwesomeModule,
		CardModule,
		FormsModule,
		ButtonModule,
		RippleModule,
		DividerModule
	]
})
export class WorkItemsModule { }
