import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatHistoryListModel, ChatHistoryModel, ChatService } from 'digiteq-ai-portal-client-lib';
import { ChatType } from '../../../models/chat-type';
import { MenuItem } from 'primeng/api';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'app-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss']
})
export class HistoryItemComponent implements OnInit{
  get editingChatName(): boolean {
    return this._editingChatName;
  }

  set editingChatName(value: boolean) {
    this._editingChatName = value;

    const handleClickOutside = (event: MouseEvent) => {
      const input = document.getElementById('edit-chat-name');
      const isClickInside = input.contains(event.target as Node);

      if (!isClickInside) {
        handleEdit();
      }
    }

    const handleEnterKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === 'Tab') {
        handleEdit();
      }
    }

    const handleEdit = () => {
      this._editingChatName = false;
      this.renameChatHistory();
      this.isHovered = false;
      this.historyMenuShown = false;
      document.body.removeEventListener('click', handleClickOutside);
      document.body.removeEventListener('keyup', handleEnterKeyUp);
    }

    if (value === true) {
      setTimeout(() => {
        document.body.addEventListener('click', handleClickOutside);
        document.body.addEventListener('keyup', handleEnterKeyUp);
      }, 0)
    }
  }

  @Input() chat: ChatHistoryListModel;
  @Input() chatType: ChatType;
  @Input() isCurrentChat: boolean;

  @Output() delete = new EventEmitter();
  @Output() rename = new EventEmitter();
  @Output() load = new EventEmitter<ChatHistoryModel>();

  historyMenuItems: MenuItem[];
  historyMenuShown = false;
  isHovered = false;
  chatName: string;
  private _editingChatName = false

  constructor(
    private chatService: ChatService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.historyMenuItems = [
      // {
      //   label: 'Share',
      //   icon: 'pi pi-upload',
      // },
      {
        label: 'Rename',
        icon: 'pi pi-pencil',
        command: () => {
          this.editingChatName = true;
        }
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {
          this.deleteChatHistory();
        }
      },
    ];

    this.chatName = this.chat.name;
  }

  loadHistory() {
    this.chatService.loadChat(this.chat.id).subscribe({
      next: chat => {
        this.load.emit(chat);
      },
      error: err => {
        this.errorService.handleError(err, 'Could not load the chat from history.')
      }
    })
  }

  deleteChatHistory() {

    this.chatService.deleteChat(this.chat.id).subscribe({
      next: () => {
        this.delete.emit(this.chat.id)
      },
      error: err => {
        this.errorService.handleError(err, 'Could not delete the chat from history.')
      }
    })
  }

  renameChatHistory() {
    this.chatService.renameChat(this.chat.id, { newName: this.chatName }).subscribe({
      next: () => {
        this.rename.next({ id: this.chat.id, newName: this.chatName })

      },
      error: err => {
        this.errorService.handleError(err, 'Could not rename your chat.')
      }
    })

  }

   onChatHistoryMenuMouseLeave() {
    if (this.historyMenuShown) {
      return;
    }
    this.isHovered = false;
  }

  onChatHistoryMenuHide() {
    this.historyMenuShown = false;
    this.isHovered = false;
  }

}
