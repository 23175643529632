import { Component } from '@angular/core';
import { ChatType } from '../../models/chat-type';

@Component({
  selector: 'app-generic-chat',
  templateUrl: './generic-chat.component.html',
  styleUrls: ['./generic-chat.component.scss']
})
export class GenericChatComponent {
  chatType = ChatType;
}
