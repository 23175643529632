import { Component, Input } from '@angular/core';
import { AiPortalItem } from '../../models/ai-portal-item';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page-tile',
  templateUrl: './landing-page-tile.component.html',
  styleUrls: ['./landing-page-tile.component.scss']
})
export class LandingPageTileComponent {
  @Input() item: AiPortalItem;

  showDescription = false;

  constructor(
    private router: Router
  ) {
  }

  onItemClick(route: string) {
    void this.router.navigate([route]);
  }
}
