export const environment = {
	apiUrl: 'https://api.openai-test.digiteq.tech',
  application: 'AI_Portal_FE_TEST',
  authentication: {
    authority: 'https://identity.tskoda.vwgroup.com/realms/standard',
    clientId: 'dqai_87c658e9',
    renewTimeBeforeTokenExpiresInSeconds: 30
  },
  appInsights: {
    endpointUrl: 'https://api.openai-test.digiteq.tech/telemetry/track',
    dummyKey: 'e63c66f8-0564-4cb1-b959-aa9b32bf4fc4'
  }
};
