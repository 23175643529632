<div class="flex-fill d-flex overflow-y-hidden">
  <div class="p-3 position-absolute">
      <div><p-button icon="fa-solid fa-list" [text]="true" severity="secondary" (onClick)="historyVisible = true"
                  pTooltip="Show chat history"/></div>
      <div><p-button icon="fa-regular fa-pen-to-square" [text]="true" severity="secondary" (onClick)="reset()"
                  pTooltip="New chat"/></div>
  </div>
  <div class="p-3 position-absolute" style="right: 0">
    <button *ngIf="isAdvancedUser && chatType === chatTypeEnum.GENERIC" pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-secondary p-button-text"
            (click)="settingsVisible = !settingsVisible" pTooltip="Settings"></button>
  </div>
  <div *ngIf="historyVisible" class="p-3 d-flex sidebar-w">
    <p-sidebar #historySidebar id="history" styleClass="chat-sidebar" [(visible)]="historyVisible" position="left"
               [modal]="false">
      <ng-template pTemplate="header">
        <div class="flex-fill">
          <h4 class="text-center">Chat history</h4>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <ng-container *ngIf="history">
          <div class="mt-1">
            <p-button pRipple
                      icon="fa-regular fa-pen-to-square"
                      label="Start new chat"
                      [text]="true" severity="secondary"
                      (click)="reset()"></p-button>
          </div>
          <div *ngFor="let group of groupedHistory">
            <ng-container *ngIf="group.items.length">
              <div class="mt-3 history-order-label">{{group.label}}</div>
              <div *ngFor="let chat of group.items">
                <app-history-item
                  [chat]="chat"
                  [chatType]="chatType"
                  [isCurrentChat]="chat.id === currentChatId"
                  (load)="onChatHistoryItemLoad($event)"
                  (rename)="onChatRename($event)"
                  (delete)="onChatHistoryItemDelete($event)"
                ></app-history-item>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div *ngIf="!history.length">
          Nothing to show...
        </div>
        <div *ngIf="loadingChatHistory" class="d-flex">
          <div class="m-auto">
            <p-progressSpinner></p-progressSpinner>
          </div>
        </div>
      </ng-template>
    </p-sidebar>
  </div>

  <div class="flex-fill d-flex flex-column overflow-y-hidden">
      <div *ngIf="showWelcomeMsg" class="page-container">
        <div class="container">
          <h1 class="text-center">Start chatting</h1>
          <p>{{description}}</p>
          <p> We use our own separate <strong>GPT</strong> models, which run securely in Microsoft Azure. The application
            allows you to chat about topics and information up to classification level <strong>confidential.</strong></p>
        </div>

      </div>

      <div #chatContainer class="overflow-y-auto flex-fill page-container">
        <div id="chat-container" class="container">
          <div class="flex-column flex-fill d-flex mx-2">
            <ng-container *ngFor="let chatMessage of messages; let i = index">
              <div class="w-100 d-flex" [ngStyle]="{'justify-content' : chatMessage.role !== role.Assistant ? 'end' : ''}">
                <div [id]="'chat-msg-' + i" class="border-1 chat-msg"
                     [ngClass]="{'ai-msg' : chatMessage.role === role.Assistant, 'err-msg' : aiResponseError && i === messages.length - 1}">
                  <ng-container *ngIf="aiResponseError && i === messages.length - 1; else message">
                    <div class="d-flex justify-content-between">
                      <i class="fa-solid fa-triangle-exclamation" style="color: red"></i>
                      <i class="fa-solid fa-arrow-rotate-right" (click)="send(true)" pTooltip="Send again"></i>
                    </div>
                    <div [innerHTML]="getFormattedTextareaValue(chatMessage.message)"></div>
                  </ng-container>
                  <ng-template #message>
                    <ng-container *ngIf="chatMessage.role === role.Assistant">
                      <div class="d-flex">
                        <div class="markdown-container">
                          <div *ngFor="let msgPart of chatMessage.messageBlocks; let i = index"
                               [ngClass]="{'textPart': isCodeSnippet(msgPart)}">
                            <div *ngIf="isCodeSnippet(msgPart)" class="d-flex justify-content-between px-2">
                              <div class="blue-400 fw-medium">Code snippet</div>
                              <i class="fa-regular fa-copy pt-1 blue-400" (click)="copyToClipboard(msgPart)"
                                 pTooltip="Copy code"></i>
                            </div>
                            <markdown katex mermaid [data]="msgPart"></markdown>
                          </div>
                        </div>
                        <div *ngIf="!aiTypingResponse || chatMessage.completed" class="ms-2">
                          <div class="d-flex justify-content-end">
                            <i class="fa-regular fa-copy" style="color: var(--primary-color)"
                               (click)="copyToClipboard(chatMessage.message)" pTooltip="Copy"></i>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div *ngIf="chatMessage.role === role.Assistant && hasUsedCitation(chatMessage)"
                         class="mt-2 d-flex flex-wrap">
                      <div class="fw-bold me-1">Citations:</div>
                      <ng-container *ngFor="let cit of chatMessage.citations">
                        <div *ngIf="cit.used" class="citation">
                          <a [href]="cit.url">{{cit.index + ". " + cit.title | slice:0:42}} {{ cit.title.length > 42 ? '...' : '' }}</a>
                        </div>
                      </ng-container>
                    </div>
                    <div *ngIf="chatMessage.role !== role.Assistant"
                         [innerHTML]="getFormattedTextareaValue(chatMessage.message)"></div>
                  </ng-template>
                </div>
              </div>
              <div class="w-100 d-flex"
                   *ngIf="aiTypingResponse && chatMessage.role !== role.Assistant && i === messages.length - 1">
                <div class="border-1 chat-msg ai-msg">
                  <span class="typing-indicator"></span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div *ngIf="messageForm" class="page-container mb-3 mt-3">
        <div class="container">
          <div class="text-container">
            <div class="d-flex">
              <div class="p-inputgroup">
            <textarea #textArea id="chat-textarea" class="text-area" pInputTextarea [formControl]="messageForm"
                      (keydown.enter)="$event.preventDefault(); send()" [rows]="1"
                      placeholder="Type your question..."></textarea>
                <p-button [icon]="aiTypingResponse ?'fa-regular fa-hand' :'fa-regular fa-paper-plane' "
                          (click)="handleActionButton()"
                          [disabled]="!aiTypingResponse && !messageForm.valid"
                          [pTooltip]="aiTypingResponse ? 'Stop generating' : 'Send message'"></p-button>
              </div>
            </div>
            <div *ngIf="hasInputValueErrors"><small class="p-error">Please enter at least 3 non-whitespace
              characters</small></div>
          </div>
          <p>
            Answers generated by AI may be incorrect.
            <a href="https://dqwiki.skoda.vwgroup.com/display/AIPortal/Digiteq+AI+portal" target="_blank">About AI Portal</a>
          </p>
        </div>
      </div>

    </div>

  <div *ngIf="settingsVisible" class="p-3 d-flex sidebar-w">
    <p-sidebar #settingsSidebar id="settings" styleClass="chat-sidebar" [(visible)]="settingsVisible" position="right"
               [modal]="false">
      <ng-template pTemplate="header">
          <div class="flex-fill">
            <h4 class="text-center">Settings</h4>
          </div>
      </ng-template>
      <app-settings></app-settings>
    </p-sidebar>
  </div>
</div>

<p-toast />
<p-confirmDialog />

