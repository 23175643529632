<div class="d-flex mt-3 mx-4">
  <div>
    <p-table dataKey="id" [value]="workItems" [tableStyle]="{ 'min-width': '50rem' }" [selectionMode]="'single'"
             [(selection)]="selectedWorkItem">
      <ng-template pTemplate="header">
        <tr>
          <th>Title</th>
          <th>Type</th>
          <th>State</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-workItem>
        <tr [pSelectableRow]="workItem">
          <td>{{ workItem.title }}</td>
          <td>
            <fa-icon *ngIf="workItem.type === workItemTypes.Bug" [icon]="faBug"></fa-icon>
            <fa-icon *ngIf="workItem.type === workItemTypes.Feedback" [icon]="faComment"></fa-icon>
            <fa-icon *ngIf="workItem.type === workItemTypes.AppFeature" [icon]="faLightBulb"></fa-icon>
            {{ workItem.type }}
          </td>
          <td>{{ workItem.state }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">No data to display.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-divider layout="vertical"></p-divider>
  <div class="">
    <app-work-item-detail class="flex-fill" [workItem]="selectedWorkItem"></app-work-item-detail>
  </div>
</div>


